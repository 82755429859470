import React from 'react';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/about.scss';

const AboutPage = ({ data }) => (
    <Layout>
        <SEO title="about" />
        <div className="headerText">
            about
        </div>
        <div className="about-content">
            <p>
                It started with a need. I <em>needed</em> to stay awake to study. I <em>needed</em>&nbsp; 
                to be at the coffee shop with my friends while studying. I <em>needed</em> to spend 
                money. Better sleep habits, study locations, or financial health were just not options.
            </p>
            <p>
                What began as sugar-filled lattes and frappes to dose me with the energy I <s>wanted</s>
                &nbsp;needed to get through each day turned to straight black cups of joe. I realized the 
                devastating amount of calories I was consuming. I hated it. But, after a couple weeks of 
                the stuff, it was fine. But some time after that, I actually grew to like it, even being 
                able to differentiate and develop preferences.
            </p>
            <p>
                Over the years, the "necessity" grew into an appreciation and the appreciation into a 
                hobby. I collected way too many ways to make coffee. I began roasting my own coffee at 
                home. I became insufferable, then chilled out (a little (I hope)). Now that passion is 
                burning to serve you.
            </p>
            <p>
                Spring City Coffee exists to make coffee snobbery approachable. I'll sling you some 
                cold brew. I'll pour you a hot cup of joe. I'll roast up some beans for you. All the 
                while, I hope to get you to see the beauty and intricacies of coffee I've come to praise
                the Maker for. It's a fun little berry seed. Come find out what coffee can be.
            </p>
            <p className="signature">
                ~ Garrett DeMeyer
                <br/>
                &nbsp;&nbsp;&nbsp;Owner
            </p>
            <Img fixed={data.file.childImageSharp.fixed} className="aboutPortrait" />
        </div>
    </Layout>
);

export const query = graphql`
query {
  file(relativePath: { eq: "about_portrait.jpg" }) {
    childImageSharp {
      fixed(width: 350) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`;

export default AboutPage;